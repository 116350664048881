import request from '@/utils/request'

// 列表
export function add(data) {
  return request({
    url: 'UseReading/Add',
    data,
    method: 'POST'
  })
}
// 删除
export function deletes(data) {
  return request({
    url: 'UseReading/Delete',
    params: data,
    method: 'get'
  })
}
// 列表
export function list() {
  return request({
    url: 'UseReading/List',
    method: 'get'
  })
}
// 删除
export function info(data) {
  return request({
    url: 'UseReading/Info',
    params: data,
    method: 'get'
  })
}
// 编辑名称
export function editorName(data) {
  return request({
    url: 'UseReading/EditorName',
    params: data,
    method: 'get'
  })
}
