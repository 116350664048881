import request from '@/utils/request'

// 获取字幕
export function getSubtitle(data) {
  return request({
    url: 'Subtitle/GetSubtitle',
    params: data,
    method: 'GET'
  })
}

// 学员自定义字幕
export function customSubtitle(data) {
  return request({
    url: 'Subtitle/CustomSubtitle',
    data,
    method: 'POST'
  })
}

// 获取字幕相关笔记/联想视频
export function getSubtitleAv(data) {
  return request({
    url: 'Note/GetSubtitleNoteAv',
    params: data,
    method: 'GET'
  })
}

// 笔记点赞
export function noteGood(data) {
  return request({
    url: 'Note/NoteGood',
    params: data,
    method: 'GET'
  })
}

// 设置字幕挖空记录
export function subtitleHollow(data) {
  return request({
    url: 'Subtitle/SubtitleHollow',
    data,
    method: 'POST'
  })
}

// 获取字幕挖空记录
export function getSubtitleHollow(data) {
  return request({
    url: 'Subtitle/GetSubtitleHollow',
    params: data,
    method: 'GET'
  })
}

// 搜索字幕例句
export function modelSentence(data) {
  return request({
    url: 'Subtitle/ModelSentence',
    data,
    method: 'POST'
  })
}

// 是否根据名称自动挖空
export function isNameHollow(data) {
  return request({
    url: 'Subtitle/IsNameHollow',
    params: data,
    method: 'get'
  })
}
// 单词过去未来查询
export function wordPastFuture(data) {
  return request({
    url: 'Subtitle/WordPastFuture',
    params: data,
    method: 'get'
  })
}

